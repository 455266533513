import MainCard from 'components/MainCard'
import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axiosInstance from 'utils/axios.config';
import { useAppContext } from 'AppContextProvider';
import ListOfProperty from './ListOfProperty';
import { useNavigate } from 'react-router-dom';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#0C1E21',
      color: theme.palette.common.white
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14
    }
  }));


export default function OwnerPropertyList() {
    
 const [OwnerPropertyList,setOwnerPropertyList] = useState([]);
const {ownerInfo} = useAppContext()
const {building_ownercode} = ownerInfo;
useEffect(() => {
  axiosInstance
    .get(`https://api.hellokompass.com/allpropertyinfobyowner?pownercode=${building_ownercode}`)
    .then((res) => {
      const data = res.data;
      if (data.code === 200) {
        setOwnerPropertyList(data?.data);
      }
    })
    .catch((error) => {
      console.error('Error:', error);
    });
}, [building_ownercode]);

const navigate = useNavigate();
const ownerListHandle = () => {
  navigate('addProOwner');
};
  return (
 <MainCard>
   <Box sx={{ display: 'flex', justifyContent: 'end' }} onClick={ownerListHandle}>
        <Button
          sx={{
            backgroundColor: '#0C1E21',
            color: '#fff',
            px: '20px',
            py: '8px',
            mb: '20px',
            '&:hover': {
              backgroundColor: '#0C1E21',
              color: '#fff'
            }
          }}
        >
          + Property List
        </Button>
      </Box>
      <Box>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Property Name</StyledTableCell>
                <StyledTableCell align="left">Property Floor</StyledTableCell>
                <StyledTableCell align="left">Property Unit</StyledTableCell>
                <StyledTableCell align="left">Property parking</StyledTableCell>
                <StyledTableCell align="left">Property Address</StyledTableCell>
                <StyledTableCell align="left">QR Code</StyledTableCell>
                <StyledTableCell align="left">Property Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {OwnerPropertyList?.map((listPro) => (
                <ListOfProperty listPro={listPro} key={listPro.property_code}></ListOfProperty>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
 </MainCard>
  )
}
